<template>
  <div class="pageContol rollStatistics">
    <div class="framePage">
      <div class="framePage-title">
        <span v-if="stu != 'ywRoll'">
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="toOrganizationList" class="cur-a">机构列表</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()" class="cur-a">学员列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">认证统计</a>
        </span>
        <span v-else>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()" class="cur-a">点名认证记录</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()" class="cur-a">点名记录</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
        style="margin-bottom: 15px;font-size:1rem;align-items: flex-start; padding-left:17px"
        class="searchbox"
        v-show="stu == 'ywRoll'"
      >
        <span>姓名：{{ userName }}</span>
        <span style="margin: 0 15px">身份证号：{{ idcard}}</span>
        <span>电话：{{ mobile }}</span>
      </div>
        <div class="seachStyle">
          <el-row :gutter="20">
            <el-col :sm="12" :md="8" :lg="5" class="searchList">
              <span class="listLabel">认证类型:</span>
              <el-select
                class="listInput"
                size="small"
                v-model="searchData.rollType"
                placeholder="请选择认证类型"
                clearable
              >
                <el-option
                  v-for="item in rollTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :sm="12" :md="8" :lg="5" class="searchList">
              <span class="listLabel">认证结果:</span>
              <el-select
                class="listInput"
                size="small"
                v-model="searchData.rollResult"
                placeholder="请选择认证结果"
                clearable
              >
                <el-option
                  v-for="item in rollResultList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-col>

            <el-col :sm="12" :md="8" :lg="5" class="searchList">
              <div class="listBtn">
                <el-button type="primary" class="listBtnS" round @click="getData()">查询</el-button>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column
                label="认证类型"
                align="center"
                show-overflow-tooltip
                prop="timeType"
                width="150px"
              >
                <span slot-scope="scope">{{$setDictionary("ROLLTYPE", scope.row.timeType)}}</span>
              </el-table-column>

              <el-table-column label="认证次数" align="center" show-overflow-tooltip prop="timeCount" />
              <el-table-column
                label="学员分组"
                align="center"
                show-overflow-tooltip
                prop="groupName"
              >
                <template slot-scope="scope">
                <span  v-if="scope.row.groupName">{{scope.row.groupName}}</span>
                <span v-else>--</span>
                 </template>
                
              </el-table-column>
              <el-table-column label="认证结果" align="center" show-overflow-tooltip prop="timeState" >
                <template slot-scope="scope">
                <span  v-if="scope.row.timeState==10">成功</span>
                <span  v-else-if="scope.row.timeState==20">失败</span>
                <span v-else>--</span>
                 </template>
                
              </el-table-column>
              <el-table-column label="认证模板" align="center" show-overflow-tooltip prop="userFace" >
                 <template slot-scope="scope">
                  <span v-if="!scope.row.approveFace">--</span>
               <el-image v-else
                class="theImgno"
                    style="width: 40px; height: 50px"
                    :src="scope.row.userFace"
                    :preview-src-list="[scope.row.userFace]"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-folder-delete"></i>
                    </div>
                  </el-image>
                 </template>
              </el-table-column>
              <el-table-column label="点名照片" align="center" show-overflow-tooltip prop="approveFace" >
                 <template slot-scope="scope">
                   <span v-if="!scope.row.approveFace">--</span>
               <el-image v-else
               class="theImgno"
                    style="width: 40px; height: 50px"
                    :src="scope.row.approveFace"
                    :preview-src-list="[scope.row.approveFace]"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-folder-delete"></i>
                    </div>
                  </el-image>
                 </template>
              </el-table-column>
              <el-table-column label="点名时间" align="center" show-overflow-tooltip prop="callTime" >
                <span slot-scope="scope">{{scope.row.callTime}}</span>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";

export default {
  name: "sjzRegulator/rollStatistics",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      stu: this.$route.query.stu,
      userName: this.$route.query.userName,
      idcard: this.$route.query.idcard,
      mobile: this.$route.query.mobile,
      rollTypeList: [], //认证类型list
      rollResultList: [
        {
          value: 10,
          label: "成功"
        },
        {
          value: 20,
          label: "失败"
        }
      ], //认证结果list
      searchData: {
        rollType: "", //认证类型
        rollResult: "" //认证结果
      }
    };
  },
  created() {},
  watch: {},
  computed: {},
  mounted() {
    this.getRollTypeList();
  },
  methods: {
    getRollTypeList() {
      const rollTypeList = this.$setDictionary("ROLLTYPE", "list");
      const list = [];
      for (const key in rollTypeList) {
        list.push({
          value: key,
          label: rollTypeList[key]
        });
      }
      this.rollTypeList = list;
    },
    //机构列表
    toOrganizationList() {
      this.$router.push({
        path: "/web/SupervisionEnd/sjzRegulator/organizationList"
      });
    },
    getData(pageNum = 1) {
      const params = {
        projectId: this.$route.query.projectId,
        userId: this.$route.query.userId,
        pageSize: this.pageSize
      };
     
      if(this.searchData.rollType){
      params.timeType=this.searchData.rollType
      }
      if(this.searchData.rollResult){
      params.timeState=this.searchData.rollResult
      }
      this.doFetch({
        url: "/sjz/project/queryProjectUserRandomRollList",
        params,
        pageNum
      });
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then(ret => {
        this.areatreeList = ret.data;
      });
    },

    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3.25) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    }
  }
};
</script>
<style lang="less">
.theImgno {
    .el-icon-circle-close {
      color: white;
    }
  }
</style>
